/* global window */
// @flow
import checkSiteFromConfig from './checkSiteFromConfig';

const { isTM, isHTZ, } = checkSiteFromConfig();

function getArticleId(url) {
  const match = url.match(/1\.\d+/);
  return match !== null ? match[0] : null;
}

export function adaptArticleLink(originPath: string, isWebView: boolean, origin: ?string) {
  const path = originPath || '';
  let href = path;
  const isTmBooster = origin === 'TmBooster';

  if (!isWebView && isTmBooster && isHTZ && !path.startsWith('https://')) {
    // Desktop/Mobile and Booster and HTZ

    const articleId = getArticleId(path);
    const articleUrl = path.includes('/') ? path.substr(path.lastIndexOf('/') + 1) : articleId;
    const hostname = 'https://themarker.com';

    href = articleUrl ? `${hostname}/${articleUrl}` : href;
  }
  // else if ((!isTmBooster || isWebView) && ((isHTZ && path.includes('themarker.com')) || (isTM && path.includes('haaretz.co.il')))) {
  //   const articleId = getArticleId(path);

  //   const articleUrl = path.startsWith('http') ? (new URL(path)).pathname : `/${articleId}`;
  //   const sectionPrefix = isTM ? '/hrtz' : '/tmr';

  //   href = articleUrl ? `${sectionPrefix}${articleUrl}` : href;
  // }

  const sectionPrefix = isTM ? '/tmr/' : '/hrtz/';

  if (((isHTZ && path.startsWith(sectionPrefix)) || (isTM && path.startsWith(sectionPrefix)))) {
    href = href.replace(sectionPrefix, '/');
  }

  return href;
}
