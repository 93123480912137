// @flow
import React from 'react';
import { useInView, } from 'react-intersection-observer';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';

import type { Node, } from 'react';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

import ListItem from '../../elements/ListItem';
import BlockLink from '../../../BlockLink/BlockLink';
import H from '../../../AutoLevels/H';
import Image from '../../../Image/Image';
import { isTeaser, } from '../../../../utils/validateType';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import useWebViewChecker from '../../../../hooks/useWebViewChecker';
import { adaptArticleLink, } from '../../../../utils/adaptArticleLink';

const imgOptions: Object = {
  transforms: {
    aspect: 'headline',
    width: '600',
  },
};

type Props = {
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
  /**
   * data object from polopoly
   */
  list: ListDataType,
  /**
   * Determine if the component should be lazyload images
   */
  isLazyloadImages: boolean,
};

function Farnsworth({
  list,
  isLazyloadImages,
  biAction,
  biImpression,
}: Props): Node {
  const { css, theme, } = useFela();
  const { title, items, } = list;
  if (!items) return null;

  return (
    <div
      className={css({
        display: 'flex',
        flexDirection: 'column',
        extend: [
          theme.mq({ from: 's', }, { display: 'none', }),
        ],
      })}
      data-test="farnworth"
    >
      <div
        className={css({
          ...theme.type(1),
          fontWeight: '700',
          color: theme.color('list', 'listViewHeader'),
          ...borderTop('2px', 1, 'solid', theme.color('list', 'listViewHeader')),
          marginBottom: '1rem',
        })}
      >
        <H>{title}</H>
      </div>
      {items.map((item, index) => (isTeaser(item) ? (
        <FarnsworthTeaser
          key={item.contentId}
          item={item}
          biAction={setBiAction(index, item, biAction)}
          biImpression={setBiImpression(index, item, biImpression)}
          isLazyloadImages={isLazyloadImages}
        />
      ) : null))}
    </div>
  );
}

export default Farnsworth;

type FarnsworthTeaserProps = {
  biAction: ?() => void,
  biImpression: () => void,
  /**
   * data object from polopoly
   */
  item: TeaserDataType,
  /**
   * Determine if the component should be lazyload images
   */
  isLazyloadImages: boolean,
};

FarnsworthTeaser.defaultProps = { biAction: null, };

function FarnsworthTeaser({
  item,
  isLazyloadImages,
  biAction,
  biImpression,
}: FarnsworthTeaserProps) {
  const { css, theme, } = useFela();
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);
  const isWebView = useWebViewChecker();

  React.useEffect(() => {
    if (inView && item && !isImpressionSent) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, inView, isImpressionSent, item, ]);

  const { title, path, image, origin, } = item;

  const href = isWebView ? adaptArticleLink(path, isWebView, origin) : path;

  return (
    <ListItem>
      <BlockLink
        href={href}
        onClick={biAction}
      >
        <div ref={ref} className={css({ marginBottom: '2rem', })}>
          <Image
            image={image}
            imgOptions={imgOptions}
            lazyLoad={isLazyloadImages}
          />
          <H
            className={css({
              fontWeight: 'bold',
              color: theme.color('bodyText', 'base'),
              marginTop: '1rem',
            })}
          >
            {title}
          </H>
        </div>
      </BlockLink>
    </ListItem>
  );
}
