// @flow
/* global window */
import * as React from 'react';
import { FARNSWORTH_LIST_QUERY, } from '@haaretz/graphql';

import FarnsworthView from './FarnsworthView.js';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import ListWrapper from '../../ListWrapper.js';
import useIsReadyToRenderExternals from '../../../../hooks/useIsReadyToRenderExternals';

type FarnsworthWrapperProps = {
  updateListDuplication: Function,
  variables: {
    offset: number,
  },
  listData: ListDataType,
  isPersonal: boolean,
};

export default function FarnsworthWrapper(
  props: FarnsworthWrapperProps
): React.Node {
  const isReadyToRenderExternals = useIsReadyToRenderExternals();
  if (!isReadyToRenderExternals) return null;
  return (
    <ListWrapper {...props} query={FARNSWORTH_LIST_QUERY} view="Farnsworth">
      {dataProps => <FarnsworthView {...dataProps} />}
    </ListWrapper>
  );
}
