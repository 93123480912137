// @flow
import { useQuery, } from 'react-apollo';
import { IS_READY_TO_RENDER_EXTERNALS, } from '@haaretz/graphql';


export default function useIsReadyToRenderExternals(): boolean {
  const { data, } = useQuery(IS_READY_TO_RENDER_EXTERNALS);

  return (data || {}).isReadyToRenderExternals !== false;
}
